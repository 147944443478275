.How {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.how-heading {
  font-weight: 700;
  font-size: 3rem;
  color: rgba(57, 57, 60, 0.8);
}
.how-heading span {
  color: #6F52EA;
}
.how-small-text {
  color: #122036;
  font-size: 14px;
}
.how-cards-container {
  width: 95%;
  max-width: 1420px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
}
.how-card {
  width: 24vw;
  height: 49vh;
  background: #fefefe;
  box-shadow: 10px 15px 15px rgba(118, 121, 199, 0.11);
  box-shadow: rgba(118, 121, 199, 0.11) 0px 9px 30px 5px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.how-card:nth-child(2) {
  margin-top: 3rem;
}
.how-card img {
  /* width: 60%; */
  height: 54%;
}
.how-card span {
  font-size: 39px;
  color: rgba(102, 104, 168, 0.56);
}
.how-card-heading {
  color: #000000;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 2rem;
}
@media (max-width: 1246px) {
  .how-cards-container {
    gap: 4rem;
  }
  .how-card {
    width: 27vw;
    height: 40vh;
  }
}
@media (max-width: 910px) {
  .how-card-heading {
    font-size: 1.2rem;
  }
  .how-card-img {
    /* width: 32%; */
  }
}
@media (max-width: 806px) {
  .How {
    height: unset;
    padding: 4rem 0;
  }
  .how-cards-container {
    grid-template-columns: unset;
  }
  .how-card {
    width: 48vw;
  }
}
@media (max-width: 500px) {
  .how-heading {
    font-size: 2.2rem;
  }
  .how-card {
    width: 70vw;
  }
}
@media (max-height: 741px) {
  .how-card img {
    /* width: 30%; */
  }
  .how-card {
    height: 50vh;
  }
}
@media (max-height: 600px) {
  .how-card img {
    /* width: 30%; */
  }
  .how-card {
    height: 54vh;
  }
}

@media (max-width: 1024px) and (min-height: 1180px) {
  .How {
    min-height: unset;
    padding: 2rem 0;
  }
  .how-card img {
    /* width: 30%; */
  }
  .how-card {
    height: 24vh;
  }
}
