.Footer {
  background: rgba(234, 234, 246, 0.29);
  min-height: 45vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-text {
  color: #263238;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 1rem;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  vertical-align: middle;
}
.bid-logo {
  z-index: 1000 !important;
  width: 6rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}
.bid-logo-small {
  width: 2rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}
.footer-links {
  list-style: none;
  display: flex;
  margin-top: 2rem;
}

.footer-links li {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 20px;
  color: #263238;
  margin-left: 2rem;
  cursor: pointer;
}
.footer-links li:first-child {
  margin-left: 0;
}

.footer-links li:hover {
  color: #6F52EA;
}
.footer-contact-list {
  margin-top: 2rem;
  display: flex;
  list-style: none;
}
.footer-contact-list a {
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 2.5rem;
}
.footer-circle {
  background-color: #efeff4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
}
.footer-contact-list a img {
  padding: 15px;
}
.footer-contact-list a:first-child {
  margin-left: 0;
}
.horizontal-line {
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 95%;
  max-width: 1420px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.copyright {
  font-weight: 300;
  font-size: 1rem;
  color: #263238;
  align-self: flex-start;
  width: 95%;
  max-width: 1420px;
  margin: 0 auto;
}
.footer-social-icon {
  font-size: 1.4rem;
  color: #6F52EA;
  margin-right: 15px;
}
.footer-socials {
  display: flex;
  margin-top: 1rem;
}
@media (max-width: 866px) {
  .Footer {
    min-height: unset;
    padding: 2rem;
  }
  .footer-links {
    flex-direction: column;
  }

  .footer-contact-list {
    flex-direction: column;
  }
  .copyright {
    text-align: center;
  }

  .footer-links li {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-left: 0;
    text-align: center;
  }
  .footer-contact-list a {
    font-size: 1.1rem;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-top: 2.5rem;
  }
  .footer-circle {
    margin-right: 0;
    margin-bottom: 1.4rem;
  }
}
@media (max-height: 900px) {
  .Footer {
    min-height: unset;
    padding: 2rem 0;
  }
}
