.register-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-left {
  width: 50%;
  height: 100%;
  background-image: linear-gradient(0deg,
      rgba(102, 104, 168, 0.35),
      rgba(102, 104, 168, 0.35)),
    url("../images/signup-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.register-left-fleet {
  background-image: linear-gradient(0deg,
      rgba(102, 104, 168, 0.35),
      rgba(102, 104, 168, 0.35)),
    url("../images/signup3.jpeg") !important;
}

.register-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  /* margin-top: 12rem;
    vertical-align: middle;
    margin-left: 6rem; */
}

.register-left-text-container span {
  display: flex;
}

.logo-cont {
  display: flex;
}

.logo-cont p {
  margin-right: 0.5rem;
}

.register-left-text-container span img {
  width: 7rem;
  /* display: inline-block; */
  vertical-align: middle;
}

.register-left-text-container span p {
  color: white;
  font-weight: 700;
  display: flex;
  font-size: 2.6rem;
  line-height: 2.6rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.register-right {
  padding: 2rem 0;

  align-self: stretch;
  width: 50%;
  height: unset;
  display: flex;
  flex-direction: column;
}

.register-right.jcc {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.register-right-container {
  width: 85% !important;
  height: 90vh;
  overflow-y: auto;
  overflow-x: none;
  display: flex !important;
  flex-direction: column !important;
  /* align-items: center !important; */
  /* justify-content: center !important; */
  margin: 0 auto !important;
  /* padding-top: 3.5rem !important; */
}

.register-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.register-right-text-container {
  display: flex;
  flex-direction: column;
}

.register-heading img {
  width: 7rem;
}

.welcome {
  font-weight: 700;
  font-size: 2rem;
  margin-top: 0.8rem;
}

.profile-photo {
  position: relative;
  width: 11rem;
  height: 11rem;
}

.profil-pic {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

.add-sign {
  position: absolute;
  bottom: 8%;
  right: 8%;
  background-color: #6F52EA;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
}

.file-input {
  position: relative;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  text-indent: 10px;
  padding: 0.8rem 0;
  outline: none;
  width: 80%;
}

.file-name {
  position: absolute;
  padding: 3px 8px 3px 5px;
  background: rgba(196, 196, 196, 0.36);
  border-radius: 6px;
  left: 3%;
  bottom: 17%;
  text-align: center;
}

.uploaded {
  background: rgb(0 128 0);
  color: white;
}

#file-input,
#file,
.file-input-image {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.slick-prev,
.slick-next {
  line-height: 0;
  position: absolute;
  top: unset !important;
  display: block;
  cursor: pointer;
  border: none;
  outline: none;
  background: unset !important;
}

button.slick-arrow {
  cursor: pointer !important;
}

.slick-prev,
.slick-next {
  width: unset !important;
  height: unset !important;
  color: white !important;
}

.slick-next:before {
  content: "Növbəti \279E" !important;
  font-family: "Nunito", sans-serif !important;
  vertical-align: middle !important;
}

.slick-next {
  background-color: #6F52EA !important;
  padding: 12px 0px !important;
  width: 68.5% !important;
  bottom: -70px !important;
  left: 7.5% !important;
  right: unset !important;
  top: unset !important;
  border-radius: 6px;
  vertical-align: middle !important;
  z-index: 1500;
}

.slick-prev:before {
  display: inline-block !important;
  font-size: 27px !important;
  color: black !important;
  background: rgba(102, 104, 168, 0.41) !important;
  content: "\27F5" !important;
  border-radius: 6px;
  padding: 2px 5px 8px 5px !important;
}

.slick-prev {
  top: 4% !important;
  left: 7.5% !important;
  z-index: 1500;
}

.slick-disabled {
  display: none !important;
}

.slick-prev:before,
.slick-next:before {
  opacity: 1 !important;
}

.slick-dots {
  display: inline-block !important;
  bottom: unset !important;
  top: 5% !important;
}

.form-register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 3rem; */
}

.form-register-inputs {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0.8rem 1.35rem;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
}

.register-field {
  display: flex;
  flex-direction: column;
}

.register-field-two-inputs {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.register-field.name,
.register-field.surname {
  width: 48% !important;
}

.register-field.name input,
.register-field.surname input {
  width: 100% !important;
}

.register-field input {
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  text-indent: 10px;
  padding: 0.8rem 0;
  outline: none;
  margin-top: 0.5rem;
  width: 80%;
}

.register-field input:focus {
  border: 1px solid #6F52EA;
}

.datetime input {
  padding-right: 15px;
}

.create-acc {
  background: #6F52EA;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  color: white;
  border: none;
  outline: none;
  padding: 1rem 0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.register-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  width: 80%;
  box-sizing: border-box;
}

/* .create-acc-buttons {
  display: flex;
  justify-content: center;
}
.register-login-btn {
  display: flex;
  margin-top: 1rem;
}
.register-login-btn a {
  text-decoration: none;
  margin-left: 0.7rem;
} */

.css-wmw4vi-ReactDropdownSelect:focus,
.css-wmw4vi-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}

.css-wmw4vi-ReactDropdownSelect:hover,
.css-wmw4vi-ReactDropdownSelect:focus-within {
  border-color: #f7941d !important;
}

.css-148o527-ItemComponent.react-dropdown-select-item-selected {
  background: #f7941d !important;
}

.css-148o527-ItemComponent:hover,
.css-148o527-ItemComponent:focus {
  background: rgba(253, 165, 0, 0.1) !important;
}

.css-wmw4vi-ReactDropdownSelect {
  border-radius: 6px !important;
}

.css-1njzwg6-InputComponent {
  margin-left: 0 !important;
  text-indent: 5px !important;
}

.css-1s2u09g-control {
  border-radius: 6px !important;
  /* position: relative !important; */
}

.css-1pahdxg-control {
  border-color: #6F52EA !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.css-1pahdxg-control :hover {
  border-color: #6F52EA !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-6j8wv5-Input {
  margin: 6px !important;
}

.css-tlfecz-indicatorContainer {
  color: rgba(38, 50, 56, 0.8) !important;
}

.css-tlfecz-indicatorContainer svg {
  width: 20px;
  height: 20px;
}

.gender-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
}

.gender-inputs label {
  margin-bottom: 5px;
}

.radio-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* .radio-btns label {
  display: flex;
  align-items: center;
  width: 40% !important;
  border: 1px solid #d9d9d9;
  padding: 10px 10px;
  border-radius: 4px;
}
.radio-btns label span {
  margin-left: 0.5rem;
} */
.form-radio-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45% !important;
}

.form-radio-input {
  display: none;
  box-sizing: border-box;
}

.form-label-radio {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 6px;
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-left: 4.5rem;
}

.form-radio-button {
  height: 1.7rem;
  width: 1.7rem;
  border: 2px solid #6F52EA;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 28%;
}

.form-radio-button::after {
  content: "";
  display: block;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #6F52EA;
  opacity: 0;
  transition: opacity 0.2s;
}

.form-radio-input:checked~.form-label-radio .form-radio-button::after {
  opacity: 1;
}

.register-field.name .error-message,
.register-field.surname .error-message {
  width: 100%;
}

.error-message {
  background: none #fff !important;
  color: #db2828 !important;
  border: 1px solid #db2828 !important;
  margin-top: 0.5rem;
  position: relative;
  font-size: 0.7rem;
  padding: 0.5rem 0.4rem;
  border-radius: 100px;
  width: 80%;
  align-self: center;
}

.error-message::before {
  content: "";
  z-index: 0;
  width: 0.6666em;
  height: 0.6666em;
  position: absolute;
  margin-top: -1px;
  border-color: inherit;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
  background-color: inherit;
  border-style: solid;
}

.register-field.phone {
  position: relative;
}

.phone-input {
  text-indent: 10% !important;
}

.country-code {
  position: absolute;
  top: 56%;
  left: 2%;
  color: #868686;
}

.search {
  width: 80%;
  position: relative;
}

.border {
  border: 1px solid #6F52EA;
}

.searchbar {
  position: relative;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

.search-input {
  width: 100%;
  outline: none;
  border: none;
  padding: 11px 0;
  background: white;
  border-radius: 6px;
  text-indent: 15px;
}

.search-input::placeholder {
  font-size: 1rem;
  font-weight: 100;
  color: rgba(110, 110, 110, 0.8);
}

.search-icon {
  position: absolute;
  /* z-index: 1800; */
  color: rgba(38, 50, 56, 0.8);
  top: 50%;
  right: 1%;
  font-size: 15px;
  font-weight: 700;
  transform: translate(-50%, -50%);
}

.filtered-results {
  max-height: 8rem;
  overflow-y: scroll;
  background-color: white;
  /* border-radius: 6px;
  border: 1px solid #d9d9d9; */
  width: 100%;
  z-index: 1600;
  position: absolute;
  margin-top: 5px;
}

.searched-service {
  padding: 10px;
  color: black;
  transition: all 0.3 ease;
}

.searched-service:hover {
  font-size: 1rem;
  color: white;
  background-color: rgba(204, 204, 204, 0.7);
}

.searched-service:last-of-type:hover {
  border-radius: 0 0 6px 6px;
}

.register-field.color label {
  margin-bottom: 10px;
}

.register-field.color {
  width: 80%;
}

@media (max-width: 1400px) {
  /* .profil-pic {
    width: 10rem;
    height: 10rem;
  }
  .add-sign {
    width: 2rem;
    height: 2rem;
  } */
   
}

@media (max-width: 1254px) {
  .register-container {
    height: unset;
  }

  .register-right {
    height: 100%;
    padding: 30px 0 30px 0;
  }

  .register-left {
    /* width: 45%; */
    height: unset;
    align-self: stretch;
  }

  .form-register-inputs {
    padding-top: 10px;
  }

  .register-profil-icon {
    margin-top: 15px;
  }

  .arrow-left {
    margin-right: 1.3rem;
    margin-left: 1.6rem;
  }

  .slick-dots {
    top: 3% !important;
  }
}

@media (max-width: 928px) {
  .slick-next {
    width: 25rem !important;
  }

  .register-buttons {
    width: 29.12rem;
  }

  .create-acc {
    width: 100%;
  }
}

@media (max-width: 1220px) {
  .register-left-text-container span {
    display: flex;
    flex-direction: column;
  }

  .register-left-text-container span p {
    line-height: 3rem;
  }
}

@media (max-width: 767px) {
  .arrow-left {
    margin-right: 0;
    margin-left: 0;
    margin-top: 10px;
  }

  .register-left {
    display: none;
  }

  .register-right {
    display: flex;
    flex-direction: column;
    width: 92%;
  }

  .register-right-container {
    width: 100%;
  }

  .register-top {
    width: 100%;
  }

  .tabs-container {
    flex-direction: column;
    align-items: center;
  }

  .form-register-inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.8rem 1.35rem;
  }

  .radio-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .form-radio-group {
    width: 48% !important;
  }

  .register-field-two-inputs {
    flex-direction: column;
  }

  .register-field.name,
  .register-field.surname {
    width: 100% !important;
  }

  .form-label-radio {
    padding: 11px;
    padding-left: 4.5rem;
  }

  .country-code {
    top: 55%;
    left: 3%;
  }

  .phone-input {
    text-indent: 16% !important;
  }

  .slick-next {
    bottom: 2% !important;
    width: 68.5% !important;
  }

  .register-buttons {
    width: 80%;
  }
  
.register-right-container {
  height: 100vh;
}
}

@media (min-height: 901px) {
  .register-container {
    min-height: 100vh;
  }

  .register-left {
    width: 50%;
    height: unset;
    align-self: stretch;
  }
}

@media (max-height: 900px) {
  .register-right {
    height: 100%;
    padding: 10px 0 30px 0;
  }

  .register-left {
    width: 50%;
    height: unset;
    align-self: stretch;
  }

  .register-heading img {
    width: 5rem;
  }

  .welcome {
    font-size: 1.8rem;
  }

}

@media (max-width: 600px) {
  .register-right-top {
    width: 100%;
  }

  .register-field input {
    width: 100%;
  }

  .register-field-two-inputs {
    width: 100%;
  }

  .search {
    width: 100%;
  }

  .error-message {
    width: 100%;
  }

  .register-field.color {
    width: 100%;
  }

  .file-input {
    width: 100%;
  }

  .gender-inputs {
    width: 100%;
  }

  .error-message {
    font-size: 0.9rem;
  }

  .slick-next {
    width: 85.5% !important;
  }

  .create-acc {
    width: 100%;
  }

  .register-buttons {
    width: 100%;
  }

  .slick-prev {
    padding: 1px 5px 14px 5px !important;

  }.react-date-picker  {
    width: 100% !important;
  }
}

@media (max-width: 340px) {
  .register-heading img {
    width: 4rem;
  }

  .welcome {
    font-size: 1.5rem;
  }

  .profile-photo {
    width: 8.8rem;
    height: 8.8rem;
  }
}

@media (max-width: 300px) {
  .form-label-radio {
    padding-left: 3.5rem;
  }
}

@media (min-height: 1024px) and (max-height: 1200px) {
  .slick-next {
    width: 29rem !important;
    bottom: 10% !important;
  }
}