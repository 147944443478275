.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  transition: all 0.3s ease;
  width: 100%;
  height: 9vh;
  z-index: 1001;
  background-color: #6f52ea;
  background-image: linear-gradient(135deg, #6f52ea 0%, rgb(90, 94, 175) 100%);
}

.navbar.nav-fixed {
  background-color: #fefefe !important;
  background-image: unset;
  height: 8vh;
  box-shadow: 0 0 10px 0 #6f52ea20;
}

.nav-container {
  width: 95%;
  max-width: 1420px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  color: white;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.logo {
  grid-column: 1 / span 2;
  width: 6rem;
  cursor: pointer;
}

.nav-links {
  grid-column: 3 / span 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links-list {
  color: white;
  list-style: none;
  font-weight: 300;
}

.link {
  font-weight: 500;
  font-size: 1.45rem;
  line-height: 21px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: inline-block;
  position: relative;
  margin-right: 22px;
  cursor: pointer;
  transition: 0.35s;
}

.nav-links.active .link {
  color: white;
}

.link.fixed {
  color: #39393c;
}

a li.link {
  margin-right: 22px;
}

li:last-child {
  margin-right: 0;
}

.link.effect-underline:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  /* height: 1em; */
  top: 100%;
  width: 100%;
  border-bottom: 0.06rem solid #6f52ea;
  margin-top: 0.66rem;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.link.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup {
  cursor: pointer;
  font-weight: 500;
  background: none;
  border-radius: 16px;
  border: none;
  color: white;
  font-size: 1.3rem;
  border: 1px solid white;
  padding: 0.8rem 2.2rem;
  outline: none;
  text-decoration: none;
}

.nav-links.active .signup {
  color: white;
}

.signup.fill {
  color: white;
  background-color: #6f52ea;
}

#dropdown {
  z-index: 1650;
  width: 48px;
  position: relative;
  top: 3.3rem;
  left: 1rem;
}

#dropdown:hover button {
  outline: none;
  border: none;
  opacity: 1 !important;
  pointer-events: auto;
}

#dropdown:hover button:nth-child(1) {
  border-radius: 8px 8px 0 0;
}

#dropdown button {
  opacity: 0;
  width: 100%;
  border-style: none;
  background: rgba(255, 255, 255, 0.33);
  padding: 0.65rem 0.73rem 0.7rem 0.73rem;
  outline: none;
  pointer-events: none;
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
  transition: all 0.3s ease;
}

#dropdown button.fill {
  background: white;
  color: #6f52ea;
  border: 1px solid #6f52ea;
}

#dropdown button.fill:hover {
  background: #6f52ea;
  color: white;
}

#dropdown button:nth-child(1) {
  opacity: 1;
  pointer-events: auto;
  cursor: auto;
  border-radius: 8px;
}

#dropdown button:nth-child(2) {
  opacity: 0;
  pointer-events: auto;
  border-top: none;
  cursor: auto;
  border-radius: none;
}

#dropdown button:last-child {
  border-radius: 0 0 8px 8px;
}

#dropdown button:hover {
  background-color: white;
  color: rgb(120, 70, 177);
  cursor: pointer;
}

.mobile-menu-social-links {
  display: none;
}
.register-buttons2 {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
@media (max-width: 1092px) {
  .logo {
    z-index: 1650;
  }

  .register-buttons2 {
    margin-top: 200px !important;
    flex-direction: column;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
  }

  .nav-links {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: #6f52ea
      linear-gradient(
        130deg,
        rgba(0, 0, 0, 0.2) 20%,
        rgba(255, 255, 255, 0.2) 78%
      );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translateX(100%);
    transition: 0.5s ease;
    z-index: 1640;
  }

  .nav-links.active {
    transform: translateX(0%);
  }

  .nav-links-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .link {
    margin-top: 25px;
    padding: 10px;
    font-weight: 500;
    font-size: 26px;
    margin-left: 0;
    margin-right: 0;
  }

  .link.effect-underline:after {
    display: none;
  }

  .nav-btns {
    display: flex;
    flex-direction: column;
  }

  .signup {
    padding: 0.8rem 2.8rem;
    border: 1px solid white;
    font-size: 1.5rem;
    margin-left: 0;
    width: 100%;
  }

  .menu-btn {
    z-index: 1650;
    right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .menu-btn.open .menu-btn__burger {
    transform: translateX(-30px);
    background: transparent;
    box-shadow: none;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(21px, -21px);
  }

  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(21px, 21px);
  }

  .menu-btn__burger {
    width: 42px;
    height: 2.6px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .menu-btn__burger.burger-color {
    background-color: #39393c;
  }

  .menu-btn .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 2.6px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .menu-btn .menu-btn__burger.burger-color::before,
  .menu-btn__burger.burger-color::after {
    background-color: #39393c;
  }

  .menu-btn__burger::before {
    transform: translateY(-9.6px);
  }

  .menu-btn__burger::after {
    transform: translateY(9.6px);
  }

  #dropdown {
    z-index: 1650;
    width: unset;
    position: unset;
    display: flex;
    margin-top: 2rem;
  }

  #dropdown:hover button {
    outline: none;
    border: none;
    opacity: unset;
    pointer-events: auto;
  }

  #dropdown:hover button:nth-child(1) {
    border-radius: unset;
  }

  #dropdown button {
    opacity: 1;
    width: 100%;
    border-style: none;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    pointer-events: none;
    font-weight: 500;
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease;
  }

  .nav-links.active #dropdown button {
    color: rgba(255, 255, 255, 0.7);
  }

  #dropdown button:nth-child(1) {
    border: unset;
    opacity: 1;
    pointer-events: auto;
    cursor: auto;
    border-radius: 10px;
  }

  #dropdown button:nth-child(2) {
    border: unset;
    opacity: 1;
    pointer-events: auto;
    border-top: none;
    cursor: auto;
    border-radius: none;
  }

  #dropdown button:last-child {
    border: unset;
    border-top: none;
    border-radius: 0 0 8px 8px;
  }

  .nav-links.active #dropdown button:hover {
    background-color: unset;
    color: white;
  }

  #dropdown button:first-child:after,
  #dropdown button:nth-child(2)::after {
    content: "";
    border-left: 1px solid white;
    height: 100%;
    margin-left: 12px;
    margin-right: 12px;
  }

  .mobile-menu-social-links.active {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .mm-social-link {
    margin-right: 15px;
  }

  .mm-social-link-icon {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
  }

  .mm-social-link-icon:hover {
    color: white;
  }

  #dropdown button.fill {
    background-color: unset;
  }
}

@media (max-height: 900px) {
  .navbar {
    height: 10vh;
    padding: 10px 0;
  }

  .navbar.nav-fixed {
    height: 9vh;
  }
}
