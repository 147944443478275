.About {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-bg {
  background: linear-gradient(
      0deg,
      rgba(102, 104, 168, 0.56),
      rgba(102, 104, 168, 0.56)
    ),
    url("../images/about-bg.jpg");
  background-size: cover;
  width: 100%;
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-bg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 95%;
  max-width: 1420px;
}

.about-heading {
  font-weight: 700;
  font-size: 42px;
  color: rgba(57, 57, 60, 0.8);
}
.about-small-text {
  font-weight: 500;
  font-size: 1.1rem;
  color: #122036;
}
.about-content {
  margin-top: 3rem;
  color: #0e0f1c;
  font-weight: 400;
  font-size: 1.5rem;
  width: 68%;
  text-align: center;
  line-height: 2rem;
  color: #0e0f1c;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.about-content span {
  font-family: "Comfortaa", cursive;
  /* margin-right: 6px; */
}
.about-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.about-bg-heading {
  font-size: 32px;
  color: #ffffff;
  font-weight: 800;
}
.about-card {
  background-color: #ffffff;
  box-shadow: 0px 20px 39px rgba(118, 121, 199, 0.11);
  border-radius: 16px;
  width: 25rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-card img {
  width: 13rem;
}

@media (max-width: 1366px) {
  .About {
    min-height: unset;
    padding: 3rem 0;
  }
  .about-content {
    width: 64%;
  }
  .about-card {
    width: 18rem;
    height: 14rem;
  }
  .about-card img {
    width: 11rem;
  }
  .about-bg {
    height: unset;
    padding: 2rem 0;
  }
}
@media (max-width: 910px) {
  .about-card {
    width: 15rem;
    height: 12rem;
  }
  .about-card img {
    width: 9rem;
  }
}
@media (max-width: 796px) {
  .about-heading {
    font-size: 2.2rem;
  }
  .about-bg {
    height: unset;
    padding: 3rem 0;
  }
  .about-bg-container {
    align-items: center;
  }
  .about-bg-heading {
    text-align: center;
  }
  .about-cards {
    flex-direction: column;
  }
  .about-content {
    width: 75%;
  }
  .about-card{
    margin-top: 2rem;
  }
}

@media (max-height: 820px) and (min-width: 1368px) {
  .About {
    min-height: unset;
    padding: 2rem 0;
  }
  .about-bg {
    height: unset;
    padding: 3rem 0;
  }
  .about-cards {
    margin-top: 1.5rem;
  }
  .about-card {
    width: 27rem;
    height: 17rem;
  }
  .about-card img {
    width: 14rem;
  }
  
}
