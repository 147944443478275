.banner {
  background: #fefefe
    linear-gradient(
      to bottom,
      rgba(254, 254, 254, 0.9) 5%,
      rgba(111, 82, 234, 0.1) 65%
    );

  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
}

.banner-content {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
  padding-top: 70px;
  align-items: center;
}
.user-driver-cards-part {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 0px;
}
.banner-left {
  color: #6f52eaee;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.banner-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}

.banner-right-image {
  /* align-self: flex-end; */
  height: 45rem;
  width: 45rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/bid-car-banner.png");
  background-position: center;
}

.banner-texts {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.banner-heading {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 3.8rem;
  display: flex;
  align-items: center;
  color: #39393c;
}

.banner-heading div span {
  font-family: "Comfortaa", cursive;
  color: #6f52eaee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.3rem 1rem;
  line-height: 0rem;
  text-align: center;
}

.banner-heading div {
  margin-left: 10px;
  background-color: white;
  border-radius: 10px;
  font-size: 4rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.banner-small-text {
  margin-top: 1.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3rem;
  color: #39393c;
}

.social-links {
  margin-top: 3rem;
  display: flex;
}

.stores-logo {
  cursor: pointer;
  width: 18rem;
}

.stores-logo:nth-child(2) {
  margin-left: 1.6rem;
}

.social-link:first-of-type {
  margin-top: 0;
}

.scroll-down {
  position: absolute;
  bottom: 5%;
  cursor: pointer;
}
.header-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
}
.header-banner-img {
  margin: 0 7px 5px 15px;
}
.navigation-driver-user {
  width: 90%;
  height: 36px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  box-shadow: 0px 0px 3.84px 1.54px #0000000d;
}
.navigation-driver-user-element {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-size: 18px;
  cursor: pointer;
}
.navigation-driver-user-element.active {
  background-color: #6f52ea;
  border-radius: 6px;
  color: white;
}
.selected-type-cards {
  width: 90%;
  margin-top: 20px;
}
@media (max-width: 1474px) {
  .banner-heading {
    font-size: 4rem;
  }

  .banner-heading div span {
    padding: 2.6rem 1rem;
  }

  .banner-heading div {
    font-size: 4rem;
  }

  .banner-small-text {
    font-size: 1.6rem;
  }

  .banner-right-image {
    height: 40rem;
    width: 40rem;
  }
}

@media (max-width: 1378px) {
  .banner-heading {
    font-size: 3.5rem;
  }

  .banner-heading div span {
    padding: 2.2rem 1rem;
  }

  .banner-heading div {
    font-size: 3.5rem;
  }

  .banner-small-text {
    font-size: 1.8rem;
  }

  .stores-logo {
    width: 14rem;
  }
}

@media (max-width: 1060px) {
  .banner {
    height: unset;
    padding-bottom: 70px !important;
    /* padding: 6rem; */
  }

  .banner-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
  }

  .banner-left {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .banner-texts {
    justify-content: center;
    align-items: center;
  }

  .banner-right {
    justify-content: center;
    width: 100%;
  }

  .banner-right-image {
    height: 30rem;
    width: 30rem;
  }

  .banner-heading {
    font-size: 3rem;
  }

  .banner-heading div span {
    padding: 2.4rem 1.5rem;
  }

  .banner-heading div {
    font-size: 3rem;
  }

  .banner-small-text {
    width: 70%;
    text-align: center;
    line-height: 4rem;
    font-size: 2.2rem;
    margin-top: 4rem;
  }

  .scroll-down {
    display: none;
  }

  .social-links {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    padding-top: 120px;
  }
}

@media (max-width: 650px) {
  .banner {
    padding: 0;
  }

  .banner-content {
    gap: unset;
  }
  .banner-right-image {
    height: 20rem;
    width: 20rem;
  }

  .banner-heading {
    font-size: 2.5rem;
  }

  .banner-heading div span {
    padding: 2rem 1.4rem;
  }

  .banner-heading div {
    font-size: 2.5rem;
  }

  .banner-small-text {
    width: 100%;
    text-align: center;
    line-height: 2rem;
    font-size: 1.6rem;
    margin-top: 1.8rem;
  }

  .social-links {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .stores-logo {
    width: 12rem;
  }
}

@media (max-width: 388px) {
  .banner-right-image {
    height: 20rem;
    width: 20rem;
  }

  .banner-heading {
    font-size: 1.8rem;
  }

  .banner-heading div span {
    padding: 1.5rem 0.8rem;
  }

  .banner-heading div {
    font-size: 1.8rem;
  }

  .banner-small-text {
    width: 100%;
    text-align: center;
    line-height: 2rem;
    font-size: 1.4rem;
    margin-top: 1.8rem;
  }

  .stores-logo {
    width: 9rem;
  }

  .social-links {
    margin-bottom: 3rem;
  }
}

@media (min-height: 1024px) and (max-height: 1366px) {
  .banner-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .banner-left {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .banner-texts {
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
  }

  .banner-right {
    justify-content: center;
    width: 100%;
  }

  .banner-right-image {
    height: 40vh;
    width: 58%;
  }

  .banner-heading {
    font-size: 6rem;
  }

  .banner-heading div span {
    padding: 5rem 1.5rem;
  }

  .banner-heading div {
    font-size: 6rem;
  }

  .banner-small-text {
    width: 70%;
    text-align: center;
    line-height: 4rem;
    font-size: 3.5rem;
    margin-top: 4rem;
  }

  .stores-logo {
    width: 30rem;
  }

  .social-links {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .scroll-down {
    display: none;
  }
}
