.Why-Bid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.vertical-line {
  display: block;
  height: 25px;
  width: 4px;
  border-radius: 8px;
  background-color: #6f52ea;
  margin-top: 4.5rem;
}
#root {
  overflow-x: hidden;
}



.about-project {
  height: 100dvh;
  width: 160px;
  position: absolute;
  top: 0;
  z-index: 499;
}
.about-project.d {
  top: 77px;
}
.about-project.active{
  position: fixed;
}
.about-project.l {
  left: 0;
}
.about-project.r {
  right: 0;
}

.about-project a, .about-project img{
  width: 100%;
}

.mobile-about-project{
  display: none;
  width: 100dvw;
  margin-top: 50px;
  z-index: 2;
  position: relative;
  margin-bottom: -40px;
}
.mobile-about-project img{
  width: 100%;
}
.mobile-about-project.m{
  margin-top: 10px;
}
@media only screen and (max-width: 948px) {
  .detail-content {
    width: 90%;
    padding: 8vh 0 2vh 0;
  }
  .about-project {
    display: none;
  }
  .mobile-about-project{
    display: flex;
  }
}
