.Faq {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Accordion {
  width: 95%;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Accordion .faq-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.5rem;
  color: black;
}
.Accordion .faq-top h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 32px;
  color: rgba(57, 57, 60, 0.8);
  /* padding-top: 140px; */
  margin-top: 2rem;
}
.Accordion .faq-top p {
  color: #606062;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  line-height: 26px;
}
.Accordion .item {
  color: black;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 25px;
  margin-bottom: 25px;
}
.Accordion .item:first-child .number {
  letter-spacing: 8.8px;
  font-size: 66px;
  margin-right: 22px;
}
.Accordion .item .number {
  font-size: 69px;
  color: rgba(255, 255, 255, 0.19);
}
.Accordion .item .texts {
  margin-left: 35px;
  width: 100%;
}
.Accordion .item .texts .title {
  display: flex;
  cursor: pointer;
  position: relative;
}
.Accordion .item .texts .title h2 {
  font-size: 22px;
  line-height: 32px;
  padding-right: 35px;
}
.title h2 span {
  font-family: "Comfortaa", cursive;
}
.Accordion .item .texts .title div {
  font-size: 30px;
  position: absolute;
  right: 0;
}
.Accordion .item .texts .title div i {
  transition: all 0.34s ease;
}
.Accordion .item .texts .title div i.open {
  transform: rotate(45deg);
}
.Accordion .item .texts .content {
  color: #6F52EA;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 24px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.001s cubic-bezier(0, 1, 0, 1);
  padding-top: 15px;
}
.texts .content span,
.faq-top p span {
  font-family: "Comfortaa", cursive;
}
.texts .content ul {
  display: flex;
  flex-direction: column;
}
.texts .content li {
  margin-top: 5px;
}
.Accordion .item .texts .content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.Accordion .item:after {
  content: "";
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
  width: 100%;
  position: absolute;
  left: 0;
  top: 120%;
}
.Accordion button {
  align-self: flex-end;
  border: none;
  background: none;
  color: #6F52EA;
  font-weight: 500;
  font-size: 18px;
  line-height: 170.7%;
  letter-spacing: 1.07px;
  margin-top: 25px;
  cursor: pointer;
}
.fa-plus {
  color: #6F52EA;
}
@media (max-width: 1290px) {
  .Accordion .faq-top p {
    width: 40%;
  }
}
@media (max-width: 1200px) {
  .Accordion .faq-top p {
    width: 45%;
  }
  .Accordion {
    width: 90%;
  }
  .Accordion .item:first-child .number {
    font-size: 63px;
  }
  .Accordion .item .number {
    font-size: 60px;
  }
  .Accordion .item .texts .title h2 {
    font-size: 20px;
    line-height: 23px;
  }
  .Accordion .item .texts .title div {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .Accordion {
    /* min-height: unset; */
  }
  .Accordion .faq-top {
    /* padding-top: 100px; */
    padding-bottom: 0;
    width: 100%;
  }
  .Accordion .faq-top h2 {
    font-size: 35px;
    margin-top: 0;
    padding-top: 50px;
    line-height: 30px;
  }
  .Accordion .faq-top p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 23px;
    width: 60%;
  }
  .Accordion .item:first-child .number {
    letter-spacing: 0;
    font-size: 54px;
  }
  .Accordion .item .number {
    font-size: 57px;
  }
  .Accordion .item .texts {
    margin-left: 20px;
  }
  .Accordion .item .texts .title h2 {
    font-size: 19px;
    line-height: 22px;
  }
  .Accordion .item .texts .title div {
    font-size: 30px;
  }
  .Accordion .item:after {
    top: 110%;
  }
  .Accordion button:hover {
    color: white;
  }
}
@media (max-width: 500px) {
  .Accordion {
    padding-top: 0;
    /* padding-bottom: 30px; */
  }
  .Accordion .faq-top {
    padding-top: 0;
  }
  .Accordion .faq-top h2 {
    font-size: 27px;
    margin-top: 0;
    padding-top: 20px;
    line-height: 30px;
  }
  .Accordion .faq-top p {
    margin-top: 0;
    font-size: 16px;
    line-height: 22px;
    width: 95%;
  }
  .Accordion .item .number {
    font-size: 45px;
  }
  .Accordion .item .texts {
    margin-left: 10px;
  }
  .Accordion .item .texts .title h2 {
    font-size: 18px;
    line-height: 21px;
  }
  .Accordion .item .texts .title div {
    font-size: 25px;
  }
}

@media (max-width: 360px) {
  .Accordion .faq-top h2 {
    font-size: 24px;
    text-align: center;
    line-height: 110.7%;
  }
  .Accordion .faq-top p {
    font-size: 15px;
  }
  .Accordion .item .number {
    font-size: 40px;
  }
  .Accordion .item .texts {
    margin-left: 15px;
  }
  .Accordion .item .texts .title h2 {
    font-size: 17px;
    line-height: 20px;
  }
  .Accordion .item .texts .title div {
    font-size: 23px;
  }
}
@media (min-height: 1180px) {
  .Faq {
    min-height: unset;
    padding: 2rem 0;
  }
}
