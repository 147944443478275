@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.card-banner {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  /* justify-content: space-between; */
  background-color: white;
  box-shadow: 0px 0px 3.84px 1.54px #0000000d;
  width: 350px;
  height: 400px;
}

.span-card-banner-1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 19px;
  color: #6f52ea;
}

.span-card-banner-2 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  color: #545353;
  text-align: left;
}
.span-card-banner-3 {
  font-family: Inter;
  color: #545353;
  font-size: 24px;
  font-weight: 300;
  text-align: right;
  line-height: 40px;
}
.span-card-banner-5 {
  color: #6f52ea;
  font-size: 24px;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
  font-family: Raleway;
}
.span-card-banner-4 {
  color: #6f52ea;
  font-size: 40px;
  font-weight: 400;
  line-height: 19px;
  text-align: right;
  font-family: Raleway;
}
.type-info {
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  margin-top: 50px;
}
.type-info img {
  width: 80px;
  height: 80px;
}
.type-info span {
  font-family: Inter;
  font-size: 25px;
  font-weight: 500;
  line-height: 33.06px;
  text-align: center;
  color: #fff;
}

.type-urls {
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  margin-top: 20px;
  gap: 15px;
  height: 40px;
}
.type-urls img {
  height: 100%;
}
.type-urls a {
  height: 100%;
}
.card-banner .right img {
  height: 100% !important;
}
.driver-card-part-desktop {
  background-image: url("../images/driverBackgroundSvg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.user-card-part-desktop {
  background-image: url("../images/userImage.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.user-card-part-desktop span {
  color: white !important;
}

@media (max-width: 768px) {
  .card-banner {
    width: 100%;
    height: 220px;
    /* justify-content: flex-end; */
  }
  .left {
    display: flex;
    flex-direction: column;
  }
  .type-info span {
    color: #6f52ea !important;
  }
  .type-info img {
    width: unset;
    height: unset;
  }

  .span-card-banner-2 {
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    color: #545353;
    text-align: right;
  }
  .span-card-banner-3 {
    font-family: Inter;
    color: #545353;
    font-size: 15px;
    font-weight: 300;
    text-align: right !important;
    line-height: 30px;
  }
  .span-card-banner-4 {
    color: #6f52ea;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    font-family: Raleway;
  }
  .driver-card-part-desktop {
    background-image: unset;
  }

  .user-card-part-desktop {
    background-image: unset;
  }
}
@media (max-width: 420px) {
  .card-banner {
    width: 100%;
    height: 160px;
    /* justify-content: flex-end; */
  }
  .span-card-banner-1 {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .span-card-banner-2 {
    font-size: 15px;
    font-weight: 400;
    margin-top: unset !important;
  }
  .span-card-banner-3 {
    font-size: 14px;
    font-weight: 300;
    line-height: unset;
    margin-top: 3px !important;
  }
  .span-card-banner-4 {
    font-size: 15px;
    line-height: 15px;
  }
  .span-card-banner-5 {
    font-size: 17px;
    line-height: 15px;
  }
}

@media (max-width: 360px) {
  .card-banner {
    width: 100%;
    height: 160px;
  }
  .left .span-card-banner-4 {
    /* font-size: 25px !important; */
    margin-top: 5px !important;
  }
  .span-card-banner-5 {
    margin-top: 2px !important;
  }
  .small-4-n {
    font-size: 15px !important;
  }
  .small-4 {
    font-size: 15px !important;
  }
}
@media (max-width: 340px) {
  .card-banner {
    width: 100%;
    height: 150px;
  }
  .left-r{
    padding: 5px !important;
  }
  .left .span-card-banner-4 {
    /* font-size: 25px !important; */
    margin-top: 5px !important;
  }
  .small-4-n {
    font-size: 15px !important;
  }
  .small-4 {
    font-size: 15px !important;
  }
  .small-3-a{
    font-size: 25px !important;
  }
}
