.Why-Bid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.why-heading {
  color: rgba(57, 57, 60, 0.8);
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.why-heading p {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.why-small-text {
  color: #122036;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 22px;
  margin-top: 1rem;
}

.cards-container {
  width: 95%;
  max-width: 1420px;
  margin-top: 3rem;
  display: grid;
  gap: 0;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  border-radius: 16px;
  box-shadow: rgba(118, 121, 199, 0.11) 0px 9px 30px 5px;
}

.card {
  width: 27rem;
  height: 45vh;
  background: #fefefe;
  /* border: 1px solid red; */
}

.card:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.card:nth-child(2) {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.card:hover {
  transform: scale(1.06);
  box-shadow: 0px 31px 45px rgba(102, 104, 168, 0.34);
  border-radius: 16px;
  background: linear-gradient(180.2deg,
      rgba(169, 171, 238, 0.91) 0.18%,
      rgba(160, 136, 186, 0.91) 106.64%);
}

.card-inner-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.card-header {
  font-weight: 700;
  font-size: 1.5rem;
  color: #6F52EA;
  margin-top: 2rem;
  width: 98%;
}

.card-content {
  font-size: 1.2rem;
  line-height: 19px;
  color: #122036;
  margin-top: 1rem;
  width: 100%;
}

.card:hover .card-header,
.card:hover .card-content {
  color: white;
}

.card-logo {
  width: 6rem;
  margin-top: 3.2rem;
  position: relative;
}

.hr-line:first-child {
  margin-top: 25px;
}

.hr-f {
  background-color: #6F52EA;
  border-radius: 8px;
  width: 40%;
  height: 2px;
  margin-top: 25px;
}

.hr-line {
  background-color: #39393CBB;
  border-radius: 8px;
  width: 40%;
  height: 2px;
  margin-top: 15px;
}

.card:hover .hr-line,
.card:hover .hr-f {
  background-color: white;
}

@media (max-width: 1680px) {
  .card {
    width: 24rem;
  }
}

@media (max-width: 1456px) {
  .card {
    width: 22rem;
  }
}

@media (max-width: 1248px) {
  .Why-Bid {
    height: unset;
    padding: 2.2rem 0;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .card:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .card:nth-child(2) {
    border-top-right-radius: 16px;
  }

  .card:nth-child(3) {
    border-bottom-left-radius: 16px;
  }

  .card:nth-child(4) {
    border-top-right-radius: unset;
    border-bottom-right-radius: 16px;
  }

  .card {
    width: 28rem;
  }

  .card:hover {
    border-radius: 16px;
  }

  .card-header {
    font-size: 1.7rem;
  }

  .card-content {
    font-size: 1.4rem;
    width: 100%;
  }
}

@media (max-width: 670px) {
  .why-heading {
    font-size: 2rem;
  }

  .why-small-text {
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  .card {
    border-radius: 16px;
    width: 33rem;
  }

  .card:hover {
    transform: unset;
    box-shadow: unset;
    background: #efeff4;
  }

  .card:hover .hr-line,
  .card:hover .hr-f {
    background-color: #6F52EA;
  }

  .card:hover .card-header,
  .card:hover .card-content {
    color: #6F52EA;
  }

  .card:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .card:nth-child(2) {
    border-top-right-radius: 16px;
    background: linear-gradient(180.2deg,
        rgba(102, 104, 168, 0.91) 0.18%,
        rgba(160, 136, 186, 0.91) 106.64%);
  }

  .card:nth-child(3) {
    border-bottom-left-radius: 16px;
  }

  .card:nth-child(4) {
    border-top-right-radius: 16px;
    background: linear-gradient(180.2deg,
        rgba(102, 104, 168, 0.91) 0.18%,
        rgba(160, 136, 186, 0.91) 106.64%);
  }

  .card:nth-child(2) .card-inner-container h2,
  .card:nth-child(2) .card-inner-container p,
  .card:nth-child(4) .card-inner-container h2,
  .card:nth-child(4) .card-inner-container p {
    color: white;
  }

  .card:nth-child(2) .card-inner-container .hr-line,
  .card:nth-child(4) .card-inner-container .hr-line {
    background-color: white;
  }
}

@media (max-width: 500px) {
  .why-heading {
    font-size: 2.2rem;
  }

  .why-small-text {
    font-size: 1.2rem;
    width: 70%;
  }

  .why-heading img {
    width: 3rem;
  }

  .card-content {
    width: 100%;
  }
}

@media (max-width: 402px) {
  .why-heading {
    flex-direction: column;
  }
}


@media (max-height: 915px) {
  .Why-Bid {
    height: unset;
    padding: 3rem 0;
  }

  .card {
    height: unset;
    padding: 2rem 0;
  }

  .card-logo {
    margin-top: 0;
  }
}

@media (min-height: 1024px) and (max-height: 1366px) {
  .Why-Bid {
    height: unset;
    padding: 1rem 0;
  }

  .card {
    height: unset;
    padding: 2.5rem 0;
  }
}