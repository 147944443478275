.register-popup {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.register-popup-content {
  width: 90%;
  height: 90%;
  background-color: white;
  max-width: 440px;
  max-height: 484px;
  border-radius: 20px;
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.register-popup-content-span-1 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.3px;
  text-align: right;
  color: #6f52ea;
  margin-top: 20px;
}

.register-popup-content-span-2 {
  font-family: Inter;
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  margin-left: 20px;
  color: #1b183f;
}
.register-popup-content-span-3 {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 30.4px;
  text-align: left;
  color: #545353;
  margin: 0 20px;
}

.register-popup-content-span-4 {
  font-family: Raleway;
  font-size: 32px;
  font-weight: 500;
  /* line-height: 20.4px; */
  text-align: right;
  color: #6f52ea;
}

.register-popup-content-span-5 {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 20.4px; */
  text-align: right;
  color: #6f52ea;
}
.react-date-picker input {
  width: auto !important;
  margin-top: unset;
  padding: 10px 5px;
  border: none;
}
.react-date-picker input:focus {
  width: auto !important;
  margin-top: unset;
  padding: 10px 5px;
  border: none;
}
.react-date-picker {
  margin-top: 0.5rem;
  width: 80%;
  border: 1px solid #e6e6e6;
  outline: none;
  border-radius: 6px;
}
.react-date-picker__wrapper {
  border: none !important;
  height: 42px;
}

.register-popup .type-info span {
  color: #6f52ea;
}
.register-popup .type-info {
  width: 100%;
  justify-content: center;
}

.register-popup .type-urls {
  width: 100%;
  justify-content: center;
}
